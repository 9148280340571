.modal-dialog{
    max-width: 65% !important;
}
.gallery-image-tab .col-md-6{
    width: 45%;
    padding: 15px;
    margin: 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 30px;
    border: 1px solid #d3d3d3;
}
.parallax-section {
    max-height: 450px !important;
    height: 450px !important;
    min-height: 450px !important;
    background-repeat: no-repeat;
    background-size: cover;
}
.pageDetailContent {
    padding: 25px 0px;
    position: relative;
    min-height: 134px;
}
.parallax-section .container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.parallax-section .container .rg-wrapper, .parallax-section .container .rgw {
    height: 100%;
    width: 100%;
}
.parallax-section .container .rg-wrapper, .parallax-section .container .rgw {
    height: 100%;
    width: 100%;
}
.parallax-section .rgw {
    color: #fff;
    width: 40%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.weta-icerik-tag {
    width: 100% !important;
    font-size: 22px;
}
.btn-custom, .btn-primary, .btn-color {
    color: #fff !important;
    background-color: #4330BF !important;
    border: 1px solid #4330BF !important;
}
.btn {
    font-size: 14px;
    border-radius: 4px;
    padding: 7px 15px;
    font-weight: 400;
    outline: none !important;
    min-width: 90px;
}
.modal-content-title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}